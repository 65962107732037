import "./Footer.scss";

import Logo from '../../assets/icons/logo_row_color_white.svg';
import Instagram from '../../assets/icons/instagram.png';
import Linkedin from '../../assets/icons/linkedin.png';

function Footer() {


    return (
        <>
            <footer>
                <div className="footer-main">
                    <div className="footer-main-col">
                        <a href="/">
                            <img className="footer-main-col-logo" alt="" src={Logo} />
                        </a>
                    </div>

                    <div className="footer-main-col">
                        <p className="footer-main-col-head">StromBären GmbH</p>
                        <p className="footer-main-col-p">Ludwigshöheweg 38</p>
                        <p className="footer-main-col-p">12559 Berlin</p>
                        <p className="footer-main-col-p">Deutschland</p>
                    </div>
                    <div className="footer-main-col">
                        <p className="footer-main-col-head">Kontakt</p>
                        <p className="footer-main-col-p footer-main-col-p-blue">Telefon:</p>
                        <p className="footer-main-col-p"><b>Mobil</b>: 0176 80236346</p>
                        <p className="footer-main-col-p"><b>Festnetz</b>: 030 80409540</p>
                        <p className="footer-main-col-p">Mo. - Fr.: 8-17 Uhr</p>
                        <br></br>
                        <p className="footer-main-col-p footer-main-col-p-blue">Mail:</p>
                        <p className="footer-main-col-p">info@strombaeren.de</p>
                    </div>
                    <div className="footer-main-col">
                        <p className="footer-main-col-head">Unsere Mission</p>
                        <p className="footer-main-col-p-mission">Die StromBären sind zuverlässige und kompetente Elektrotechniker mit einem Hohen Qualitätsanspruch. Wir unterstützen die Energiewende durch Innovation und Kooperation.</p>
                    </div>
                    <div className="footer-main-col">
                        <p className="footer-main-col-head-end">Folge uns</p>
                        <div className="footer-main-col-social">
                            <div className="footer-main-col-social-filler">

                            </div>
                            <a href="https://www.instagram.com/strombaeren/?r=nametag" target="_blank">
                                <img alt="Instagram" src={Instagram} />
                            </a>
                            <a href="https://www.linkedin.com/company/stromb%C3%A4ren-gmbh/about/" target="_blank">
                                <img alt="Linkedin" src={Linkedin} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-main-tablet">
                    <a href="/">
                        <img className="footer-main-col-logo" alt="" src={Logo} />
                    </a>
                    <div className="footer-main-row">
                        <div className="footer-main-col">
                            <p className="footer-main-col-head">StromBären GmbH</p>
                            <p className="footer-main-col-p">Ludwigshöheweg 38</p>
                            <p className="footer-main-col-p">12559 Berlin</p>
                            <p className="footer-main-col-p">Deutschland</p>
                        </div>
                        <div className="footer-main-col">
                            <p className="footer-main-col-head">Kontakt</p>
                            <p className="footer-main-col-p footer-main-col-p-blue">Telefon:</p>
                            <p className="footer-main-col-p"><b>Mobil</b>: 0176 80236346</p>
                            <p className="footer-main-col-p"><b>Festnetz</b>: 030 80409540</p>
                            <p className="footer-main-col-p">Mo. - Fr.: 8-17 Uhr</p>
                            <br></br>
                            <p className="footer-main-col-p footer-main-col-p-blue">Mail:</p>
                            <p className="footer-main-col-p">info@strombaeren.de</p>
                        </div>
                    </div>
                    <div className="footer-main-row">
                        <div className="footer-main-col">
                            <p className="footer-main-col-head">Unsere Mission</p>
                            <p className="footer-main-col-p-mission">Die StromBären sind zuverlässige und kompetente Elektrotechniker mit einem Hohen Qualitätsanspruch. Wir unterstützen die Energiewende durch Innovation und Kooperation.</p>
                        </div>
                        <div className="footer-main-col footer-main-col-center">
                            <p className="footer-main-col-head">Folge uns</p>
                            <div className="footer-main-col-social">
                                <a href="https://www.instagram.com/strombaeren?r=nametag" target="_blank">
                                    <img alt="Instagram" src={Instagram} />
                                </a>
                                <a href="https://www.instagram.com/strombaeren?r=nametag" target="_blank">
                                    <img alt="Linkedin" src={Linkedin} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-closer-desktop">
                    <p className="footer-closer-p">© 2022 - StromBären Gmbh</p>
                    <div className="footer-closer-privacy">
                        <a className="footer-closer-privacy-link" href="/impressum">Impressum</a>
                        <a className="footer-closer-privacy-link" href="/privacy">Datenschutz</a>
                    </div>
                </div>
                <div className="footer-closer-phone">
                    <div className="footer-closer-privacy">
                        <a className="footer-closer-privacy-link" href="/privacy">Datenschutz</a>
                        <a className="footer-closer-privacy-link" href="/impressum">Impressum</a>
                    </div>
                    <p className="footer-closer-p">© 2022 - StromBären GmbH</p>
                </div>
            </footer>
        </>

    );
}


export default Footer;
