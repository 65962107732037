import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./Career.scss";


function Career() {
    return (
        <>
            <Header></Header>
            <div className="career">
                <div className="career-title">
                    <div className="career-title-black">
                        <h3>MIT UNS IN DIE ZUKUNFT</h3>
                        <h1>Karriere</h1>
                        <p> Zusammen sind wir bärenstark!</p>

                    </div>
                    <div className="career-title-blue"> 
                    </div>
                </div>
                <div className="career-content">
                    <p className="career-content-head">WIR WÜRDEN UNS FREUEN DICH <br></br> ALS TEAMMITGLIED ZU BEGRÜSSEN</p>
                    <p className="career-content-subhead">Ausbildung</p>
                    <p className="career-content-text">Wie wäre es mit dualer Ausbildung statt trockenem Studium?</p>
                    <p className="career-content-text">Wir möchten junge Menschen dazu motivieren einen anderen Weg einzuschlagen, als es die meisten Medien und Menschen in Ihrem Umfeld propagieren.</p>
                    <p className="career-content-text">Der Beruf der Elektrofachkraft ist in sämtlichen Bereichen sehr gefragt, zukunftsträchtig und bietet eine abgesicherte Perspektive. </p>
                    <p className="career-content-text">Wir bemühen uns sehr eine hochwertige Ausbildung anzubieten und Anreize für eine Laufbahn im Handwerk zu setzen, also bewirb Dich jetzt!</p>

                    <p className="career-content-subhead">Fachkräfte</p>
                    <p className="career-content-text">Da wir stetig wachsen, sind wir auf der Suche nach Mitarbeitern und Kollegen. Dich erwartet ein junges und zielstrebiges Team sowie gute Entlohnung und weitere Anreize wie z.B. ein eigener Firmenwagen und eine Urban Sports Club Mitgliedschaft</p>

                    <p className="career-content-text">Mitbringen solltest Du:</p>
                    <ul>
                        <li className="career-content-text">Spaß an der Arbeit in der Elektrotechnik </li>
                        <li className="career-content-text">Teamfähigkeit</li>
                        <li className="career-content-text">Grundlegende Erfahrung</li>
                        <li className="career-content-text">Eigeninitiative und Selbstbewusstsein</li>
                    </ul>
                </div>

                <div className="career-closer">
                    <div className="career-closer-ready">
                        <p className="career-closer-head">Bereit für uns?</p>
                        <p className="career-closer-subhead">Schick uns eine Mail</p>
                        <div className="career-closer-sendmail-1">
                            <a href="/contact">EMAIL SENDEN</a>
                        </div>
                    </div>
                    <div className="career-closer-questions">
                        <p className="career-closer-head">Noch Fragen?</p>
                        <p className="career-closer-subhead">Rufe uns gerne an</p>
                        <div className="career-closer-sendmail-2">
                            <a href="/contact">ZUM KONTAKT</a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default Career;
