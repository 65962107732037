import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Home from './root/Home';

//==================== ROUTING ========================
import { BrowserRouter as Router, Routes, Route, Link as RouterLink } from 'react-router-dom';
import NotFound from './views/notfound/NotFound';
import Career from './views/career/Career';
import Contact from './views/contact/Contact';
import Company from './views/company/Company';
import Services from './views/services/Services';
import Impressum from './views/impressum/Impressum';
import Privacy from './views/privacy/Privacy';
import Mail_success from './views/mail/Mail_success';
import Mail_error from './views/mail/Mail_error';


const root = ReactDOM.createRoot(
  document.getElementById('app') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='/services' element={<Services></Services>}></Route>
        <Route path='/about' element={<Company></Company>}></Route>
        <Route path='/career' element={<Career></Career>}></Route>
        <Route path='/contact' element={<Contact></Contact>}></Route>
        <Route path='/impressum' element={<Impressum></Impressum>}></Route>
        <Route path='/privacy' element={<Privacy></Privacy>}></Route>
        <Route path='/mail_success' element={<Mail_success></Mail_success>}></Route>
        <Route path='/mail_error' element={<Mail_error></Mail_error>}></Route>
        <Route path='/*' element={<NotFound></NotFound>}></Route>
      </Routes>
    </Router>

  </React.StrictMode>
);
