import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./Mail.scss";


function Mail_success() {
    return (
        <>
            <Header></Header>
            <div className="mail-page">
                <div className="mail-page-text">
                    <p className="mid"> Vielen Dank für Ihre Nachricht!</p>
                    <p className="small">Wir werden uns bei Ihnen melden</p>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default Mail_success;
