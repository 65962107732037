import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./Services.scss";

import Service1 from '../../assets/graphics/serv-smarthome-2.jpg';
import Service2 from '../../assets/graphics/serv-storage.jpg';
import Service3 from '../../assets/graphics/serv-smarthome.jpg';
import Service4 from '../../assets/graphics/serv-powerstorage1.jpg';
import Service5 from '../../assets/graphics/serv-charging-2.jpg';
import Service6 from '../../assets/graphics/serv-charging-station1.jpg';

import Partner1 from '../../assets/graphics/senec-logo2.jpg';
import Partner2 from '../../assets/graphics/easee-logo.png';
import Partner3 from '../../assets/graphics/solarEdge-logo.png';
import Partner4 from '../../assets/graphics/tesla-powerwall-certified-installer-logo.png';
import Partner5 from '../../assets/graphics/electrify-logo.png';
import Partner6 from '../../assets/graphics/urbanSportsClub-logo.png';


import Logo from '../../assets/graphics/logo.png';

function Services() {
    return (
        <>
            <Header></Header>
            <div className="services">
                <div className="services-title">
                    <div className="services-title-back">
                        <div className="services-title-back-text">
                            <h1 className="services-title-back-text-desc-h1">Elektrotechnik</h1>
                            <h2 className="services-title-back-text-desc-h2">Schaltschränke, Steckdosen und mehr</h2>
                            <p className="services-title-back-text-desc">Eine hochwertige Elektrotechnik steht für: Sicherheit, Langlebigkeit, Komfort bei gleichzeitig gutem Aussehen.</p>
                        </div>
                    </div>
                </div>

                <div className="services-quality">
                    <p className="services-quality-head">Qualität und Wünsche</p>
                    <p className="services-quality-text">Wir verwenden ausschließlich hochwertiges Markenmaterial und bieten Ihnen gerne unsere Beratung an. Vor allem wenn es darum geht vom schlichten standard Aussehen abzuweichen.
                        Lassen Sie sich von unseren erfolgreichen Rezensionen inspirieren oder sprechen Sie uns direkt mit Ihren Wünschen und Vorstellungen an.</p>
                </div>

                <div className="service-gallery">
                    <div className="service-gallery-row">
                        <img className="service-gallery-row-img" alt="" src={Service1} />
                        <img className="service-gallery-row-img" alt="" src={Service2} />
                        <img className="service-gallery-row-img" alt="" src={Service3} />
                    </div>
                    <div className="service-gallery-row">
                        <img className="service-gallery-row-img" alt="" src={Service4} />
                        <img className="service-gallery-row-img" alt="" src={Service5} />
                        <img className="service-gallery-row-img" alt="" src={Service6} />
                    </div>
                </div>

                <div className="service-gallery-mobile">
                        <img className="service-gallery-row-img" alt="" src={Service1} />
                        <img className="service-gallery-row-img" alt="" src={Service2} />
                        <img className="service-gallery-row-img" alt="" src={Service3} />
                        <img className="service-gallery-row-img" alt="" src={Service4} />
                        <img className="service-gallery-row-img" alt="" src={Service5} />
                        <img className="service-gallery-row-img" alt="" src={Service6} />
                </div>


                <div className="service-main">
                    <div className="service-main-header">
                        <div className="service-main-header-left">
                            <p className="service-main-header-left-head">Unsere Leistungen</p>
                            <p className="service-main-header-left-text">Alles auf einem Blick</p>
                        </div>
                        <div className="service-main-header-right">
                            <p className="service-main-header-right-text">Wir sind die Strom Bären GmbH. Ein inhabergeführtes Elektroinstallationsunternehmen in dritter Generation, welches 2021 neu gegründet wurde als Nachfolger des bereits etablierten und erfolgreichen Unternehmens Elektrotechnik Kubalczak mit Sitz in Berlin.</p>
                        </div>
                    </div>

                    <div className="service-main-content-desktop">
                        <div className="service-main-content-desktop-column">
                            <div className="service-main-content-cell">
                                <img className="service-main-logos-img" alt="" src={Logo} />
                                <div className="service-main-content-text">
                                    <p className="service-main-content-text-head">ZÄHLERSCHRÄNKE &#38; VERTEILER</p>
                                    <p className="service-main-content-text-desc">Eine unserer Kernkompetenzen haben wir im Zählerschrank – und Verteilungsbau im Berlin/Brandenburger Wohnungs- und Gewerbebereich entwickelt. Jahrelange Expertise und viele zufriedene Kunden können das bestätigen.</p>
                                </div>
                            </div>
                            <div className="service-main-content-cell">
                                <img className="service-main-logos-img" alt="" src={Logo} />
                                <div className="service-main-content-text">
                                    <p className="service-main-content-text-head">LADESÄULEN &#38; WALLBOXEN</p>
                                    <p className="service-main-content-text-desc">Als StromBären fühlen wir uns verpflichtet, zukunftsorientiert und umweltfreundlich zu handeln. Deshalb entgegnen wir dem Ausbau einer Ladeinfrastruktur für Elektrofahrzeuge mit vollem Tatendrang. Für gewerbliche Kunden im großem Maßstab oder auch Privathaushalte mit einer Wallbox sind wir der richtige Ansprechpartner.</p>
                                </div>
                            </div>
                        </div>
                        <div className="service-main-content-desktop-column">
                            <div className="service-main-content-cell">
                                <img className="service-main-logos-img" alt="" src={Logo} />
                                <div className="service-main-content-text">
                                    <p className="service-main-content-text-head">SANIERUNG &#38; MODERNISIERUNG</p>
                                    <p className="service-main-content-text-desc">Die Wohnungsnot im Ballungsraum Berlin und ganz Deutschland ist in aller Munde und es gibt sehr viele Wohnungen, die das letzte Mal vor über 40 Jahren gebaut oder saniert wurden. Leider ist das jedoch lange nicht mehr Stand der Technik. An dieser Stelle möchten wir unseren Beitrag leisten, um diese Missstände durch Sanierung und technische Modernisierung aufheben.</p>
                                </div>
                            </div>
                            <div className="service-main-content-cell">
                                <img className="service-main-logos-img" alt="" src={Logo} />
                                <div className="service-main-content-text">
                                    <p className="service-main-content-text-head">PHOTOVOLTAIK &#38; STROMSPEICHER</p>
                                    <p className="service-main-content-text-desc">Eine nachhaltige Zukunft sehen wir nur mithilfe des Ausbaus einer dezentralen Energieversorgung. Photovoltaikanlagen und Stromspeicher mit neusten Batterietechnologien sind so preiswert wie noch nie. Wir sind kompetente und mehrfach zertifizierte Partner für die sog. AC-Installation von Photovoltaikanlagen in Privathaushalten.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-main-content-mobile">
                        <div className="service-main-content-cell">
                            <img className="service-main-logos-img" alt="" src={Logo} />
                            <div className="service-main-content-text">
                                <p className="service-main-content-text-head">ZÄHLERSCHRÄNKE &#38; VERTEILER</p>
                                <p className="service-main-content-text-desc">Eine unserer Kernkompetenzen haben wir im Zählerschrank – und Verteilungsbau im Berlin/Brandenburger Wohnungs- und Gewerbebereich entwickelt. Jahrelange Expertise und viele zufriedene Kunden können das bestätigen.</p>
                            </div>
                        </div>
                        <div className="service-main-content-cell">
                            <img className="service-main-logos-img" alt="" src={Logo} />
                            <div className="service-main-content-text">
                                <p className="service-main-content-text-head">SANIERUNG &#38; MODERNISIERUNG</p>
                                <p className="service-main-content-text-desc">Die Wohnungsnot im Ballungsraum Berlin und ganz Deutschland ist in aller Munde und es gibt sehr viele Wohnungen, die das letzte Mal vor über 40 Jahren gebaut oder saniert wurden. Leider ist das jedoch lange nicht mehr Stand der Technik. An dieser Stelle möchten wir unseren Beitrag leisten, um diese Missstände durch Sanierung und technische Modernisierung aufheben.</p>
                            </div>
                        </div>
                        <div className="service-main-content-cell">
                            <img className="service-main-logos-img" alt="" src={Logo} />
                            <div className="service-main-content-text">
                                <p className="service-main-content-text-head">LADESÄULEN &#38; WALLBOXEN</p>
                                <p className="service-main-content-text-desc">Als StromBären fühlen wir uns verpflichtet, zukunftsorientiert und umweltfreundlich zu handeln. Deshalb entgegnen wir dem Ausbau einer Ladeinfrastruktur für Elektrofahrzeuge mit vollem Tatendrang. Für gewerbliche Kunden im großem Maßstab oder auch Privathaushalte mit einer Wallbox sind wir der richtige Ansprechpartner.</p>
                            </div>
                        </div>
                        <div className="service-main-content-cell">
                            <img className="service-main-logos-img" alt="" src={Logo} />
                            <div className="service-main-content-text">
                                <p className="service-main-content-text-head">PHOTOVOLTAIK &#38; STROMSPEICHER</p>
                                <p className="service-main-content-text-desc">Eine nachhaltige Zukunft sehen wir nur mithilfe des Ausbaus einer dezentralen Energieversorgung. Photovoltaikanlagen und Stromspeicher mit neusten Batterietechnologien sind so preiswert wie noch nie. Wir sind kompetente und mehrfach zertifizierte Partner für die sog. AC-Installation von Photovoltaikanlagen in Privathaushalten.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service-enviroment">
                    <p className="service-enviroment-head">ZUKUNFT UND UMWELTSCHUTZ</p>
                    <p className="service-enviroment-text">Um unseren Sinn für Nachhaltigkeit Ausdruck zu verleihen, haben wir es uns als Ziel gesetzt, bis zum Jahr 2027 unsere gesamte Flotte auf Elektrofahrzeuge umzustellen. Mit den StromBären kriegen Sie einen Partner, der kaum zu übertreffen ist.</p>
                </div>

                <div className="service-partners">
                    <p className="service-partners-head">Unsere Partner</p>
                    <p className="service-partners-text">Wir arbeiten mit den besten und rennomiertesten Geschäftspartnern zusammen. Mit ihnen erreichen wir Ihre Ziele.</p>
                    <div className="service-partner-logos-desktop">
                        <img className="service-partner-logos-img" alt="" src={Partner1} />
                        <img className="service-partner-logos-img" alt="" src={Partner2} />
                        <img className="service-partner-logos-img" alt="" src={Partner3} />
                        <img className="service-partner-logos-img" alt="" src={Partner4} />
                        <img className="service-partner-logos-img" alt="" src={Partner5} />
                        <img className="service-partner-logos-img" alt="" src={Partner6} />
                    </div>

                    <div className="service-partner-logos-mobile">
                        <div className="service-partner-logos-mobile-col">
                            <img className="service-partner-logos-img" alt="" src={Partner1} />
                            <img className="service-partner-logos-img" alt="" src={Partner3} />
                            <img className="service-partner-logos-img" alt="" src={Partner5} />
                        </div>
                        <div className="service-partner-logos-mobile-col">
                            <img className="service-partner-logos-img" alt="" src={Partner2} />
                            <img className="service-partner-logos-img" alt="" src={Partner4} />
                            <img className="service-partner-logos-img" alt="" src={Partner6} />
                        </div>
                    </div>

                </div>
                <div className="service-contact">
                    <div className="service-contact-wrapper">
                        <div className="service-contact-left">
                            <p className="service-contact-left-text">Interesse geweckt?</p>
                        </div>
                        <div className="service-contact-right">
                            <p className="service-contact-right-text">Dann melden Sie sich doch bei uns. Wir sind stets für Sie da.</p>
                            <div className="service-contact-right-button">
                                <a href="/contact" className="service-contact-right-text">KONTAKTIEREN SIE UNS</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default Services;
