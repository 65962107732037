import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./Contact.scss";


import Location from '../../assets/icons/location-sign.svg';
import Call from '../../assets/icons/call.svg';
import Mail from '../../assets/icons/email.svg';

function Contact() {
    return (
        <>
            <Header></Header>
            <div className="contact">
                <div className="contact-info">
                    <div className="contact-info-child">
                        <img className="contact-info-child-img" alt="" src={Location} />
                        <p className="contact-info-child-head">HAUPTSITZ</p>#
                        <div className="contact-info-child-desc-box">
                            <p className="contact-info-child-desc"> Hindenburgdamm 56</p>
                            <p className="contact-info-child-desc"> 12203 Berlin-Steglitz</p>
                            <p className="contact-info-child-desc"> Deutschland</p>
                        </div>
                    </div>
                    <div className="contact-info-child">
                        <img className="contact-info-child-img" alt="" src={Call} />
                        <p className="contact-info-child-head">TELEFON</p>
                        <div className="contact-info-child-desc-box">
                            <p className="contact-info-child-desc"> <b>Mobile:</b> 0176 80236346</p>
                            <p className="contact-info-child-desc"> <b>Festnetz:</b> 030 80409540</p>
                        </div>
                    </div>
                    <div className="contact-info-child">
                        <img className="contact-info-child-img" alt="" src={Mail} />
                        <p className="contact-info-child-head">EMAIL</p>
                        <div className="contact-info-child-desc-box">
                            <a className="contact-info-child-a" href="mailto:info@strombaeren.de"> info@strombaeren.de </a>
                        </div>
                    </div>

                </div>
                <div>
                    <form className="contact-form" action="/action_page.php" method="POST">
                        <p className="contact-form-head"> Schreiben Sie uns!</p>
                        <input name="name" id="name" type="text" placeholder="Ihr Name" className="contact-form-input" required></input>
                        <input name="email" id="email" type="text" placeholder="Ihre EMail Adresse" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" title="Ungültige EMail Adresse" className="contact-form-input" required></input>
                        <textarea name="msg" id="msg" placeholder="Hier Ihre Fragen und Wünsche"></textarea>
                        <div className="contact-form-privacy">
                            <input type="checkbox" id="privacy" name="privacy" required></input>
                            <p  className="contact-form-label" >Ich habe die Information zum Datenschutz zur Kenntnis genommen</p>
                        </div>
                        <a className="contact-form-privacy-link" href="/privacy" target="_blank"><p className="contact-form-privacy-link-p">Informationen zum Datenschutz</p></a>

                        <input type="submit" className="contact-form-submit"></input>
                    </form>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default Contact;
