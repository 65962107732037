import React from 'react';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import './Home.scss';


import Gall1_1 from '../assets/graphics/gal3_1.jpg';
import Gall1_2 from '../assets/graphics/gal3_2.jpg';
import Gall1_3 from '../assets/graphics/gal3_3.jpg';
import Gall1_4 from '../assets/graphics/gal3_4.jpg';

import ServKitchen from '../assets/graphics/serv-kitchen.jpg';


import Instagram from '../assets/icons/instagram.png';
import Linkedin from '../assets/icons/linkedin.png';

import Innung from '../assets/graphics/innung-premium-marken-logo.jpg';

import SmartHome from '../assets/icons/smarthome.svg';
import Solar from '../assets/icons/sonnenkollektor.svg';
import Cabinets from '../assets/icons/cabinets.svg';
import Charging from '../assets/icons/charging.svg';

import TeamMember from '../assets/graphics/landp-team-member.jpg';

import AboutGal1 from '../assets/graphics/priv-charging-station.jpeg';
import AboutGal2 from '../assets/graphics/serv-charging-1.jpg';
import AboutGal3 from '../assets/graphics/serv-powerbox.jpeg';
import AboutGal4 from '../assets/graphics/landing-about.png';

function App() {
  return (
    <>
      <Header></Header>
      <div className="home">
        <div className="home-sec1">
          <div className="home-header">
            <div className="home-header-back">
              <h1 className="home-header-h1">Willkommen bei den StromBären</h1>
            </div>
          </div>
          <div className="home-sec1-service-gallery">
            <div className="home-sec1-service-gallery-container">
              <div className="home-sec1-service-gallery-service">
                <img className="home-sec1-service-gallery-service-img" alt="" src={SmartHome} />
                <p className="home-sec1-service-gallery-service-head">SMARTHOME</p>
                <p className="home-sec1-service-gallery-service-text">Bei allen Fragen rund um die Elektroinstallation in Ihrem Gebäude sind wir der richtige Ansprechpartner. Als Elektrotechniker in dritter Generation tragen wir den Strom in unseren Genen.</p>
              </div>
              <div className="home-sec1-service-gallery-ghost"></div>
            </div>
            <div className="home-sec1-service-gallery-container">
              <div className="home-sec1-service-gallery-ghost"></div>
              <div className="home-sec1-service-gallery-service">
                <img className="home-sec1-service-gallery-service-img" alt="" src={Solar} />
                <p className="home-sec1-service-gallery-service-head">PHOTOVOLTAIK</p>
                <p className="home-sec1-service-gallery-service-text">In Zusammenarbeit mit renommierten Partnern verwirklichen wir Ihren Wunsch nach Unabhängigkeit.</p>
              </div>
            </div>
            <div className="home-sec1-service-gallery-container">
              <div className="home-sec1-service-gallery-service">
                <img className="home-sec1-service-gallery-service-img" alt="" src={Cabinets} />
                <p className="home-sec1-service-gallery-service-head">STROMSPEICHER</p>
                <p className="home-sec1-service-gallery-service-text">Eine Photovoltaikanlage ohne Stromspeicher? Mittlerweile unvorstellbar. Fortschritte im Bereich der Batterietechnik ermöglichen eine hocheffiziente Nutzung des Solarstroms.</p>
              </div>
              <div className="home-sec1-service-gallery-ghost"></div>
            </div>
            <div className="home-sec1-service-gallery-container">
              <div className="home-sec1-service-gallery-ghost"></div>
              <div className="home-sec1-service-gallery-service">
                <img className="home-sec1-service-gallery-service-img" alt="" src={Charging} />
                <p className="home-sec1-service-gallery-service-head">LADESÄULEN</p>
                <p className="home-sec1-service-gallery-service-text">Die Zukunft des Autos ist elektrisch! Davon sind wir als Unternehmen überzeugt und bieten Ihnen eine individuell auf sie angepasste Ladeinfrastruktur an.</p>
              </div>
            </div>
          </div>
          <div className="home-sec1-service-gallery-tablet-container">
          <div className="home-sec1-service-gallery-tablet">
            <div className="home-sec1-service-gallery-container">
              <div className="home-sec1-service-gallery-service">
                <img className="home-sec1-service-gallery-service-img" alt="" src={SmartHome} />
                <p className="home-sec1-service-gallery-service-head">SMARTHOME</p>
                <p className="home-sec1-service-gallery-service-text">Bei allen Fragen rund um die Elektroinstallation in Ihrem Gebäude sind wir der richtige Ansprechpartner. Als Elektrotechniker in dritter Generation tragen wir den Strom in unseren Genen.</p>
              </div>
              <div className="home-sec1-service-gallery-ghost"></div>
            </div>

            <div className="home-sec1-service-gallery-container">
              <div className="home-sec1-service-gallery-ghost"></div>
              <div className="home-sec1-service-gallery-service">
                <img className="home-sec1-service-gallery-service-img" alt="" src={Solar} />
                <p className="home-sec1-service-gallery-service-head">PHOTOVOLTAIK</p>
                <p className="home-sec1-service-gallery-service-text">In Zusammenarbeit mit renommierten Partnern verwirklichen wir Ihren Wunsch nach Unabhängigkeit.</p>
              </div>
            </div>
          </div>
          <div className="home-sec1-service-gallery-tablet">
            <div className="home-sec1-service-gallery-container">
              <div className="home-sec1-service-gallery-service">
                <img className="home-sec1-service-gallery-service-img" alt="" src={Cabinets} />
                <p className="home-sec1-service-gallery-service-head">STROMSPEICHER</p>
                <p className="home-sec1-service-gallery-service-text">Eine Photovoltaikanlage ohne Stromspeicher? Mittlerweile unvorstellbar. Fortschritte im Bereich der Batterietechnik ermöglichen eine hocheffiziente Nutzung des Solarstroms.</p>
              </div>
              <div className="home-sec1-service-gallery-ghost"></div>
            </div>

            <div className="home-sec1-service-gallery-container">
              <div className="home-sec1-service-gallery-ghost"></div>
              <div className="home-sec1-service-gallery-service">
                <img className="home-sec1-service-gallery-service-img" alt="" src={Charging} />
                <p className="home-sec1-service-gallery-service-head">LADESÄULEN</p>
                <p className="home-sec1-service-gallery-service-text">Die Zukunft des Autos ist elektrisch! Davon sind wir als Unternehmen überzeugt und bieten Ihnen eine individuell auf sie angepasste Ladeinfrastruktur an.</p>
              </div>
            </div>
          </div>
          </div>


          <div className="home-sec1-appointment">
            <div className="home-sec1-appointment-left">
              <p className="home-sec1-appointment-left-text">Vereinbaren Sie einen Termin.</p>
            </div>
            <div className="home-sec1-appointment-rigth">
              <p className="home-sec1-appointment-rigth-text">Bei uns werden Sie individuell beraten und können sich auf ein hohes Maß an Qualität und Zuverlässigkeit stützen.</p>
              <div className="home-sec1-appointment-rigth-button">
                <a href="/services" className="home-sec1-appointment-rigth-button-text">MEHR ERFAHREN</a>
              </div>
            </div>
          </div>
        </div>

        <div className="home-sec2">
          <div className="home-sec2-topback"></div>
          <div className="home-sec2-bottback"></div>
          <div className="home-sec2-bluerigth"></div>
          <div className="home-sec2-whiteleft">
            <p className="home-sec2-whiteleft-first">ERNEUERBARE ENERGIEN</ p>
            <p className="home-sec2-whiteleft-second">Wir sind die StromBären</p>
            <p className="home-sec2-whiteleft-third">Vertrauen in Elektrotechnik</p>
          </div>
          <img className="home-sec2-img" alt="" src={ServKitchen} />
        </div>

        <div className="home-characteristics">
          <p className="home-characteristics-head">Unsere Merkmale</p>
          <p className="home-characteristics-subhead">Auf uns können Sie sich verlassen. Wir arbeiten kunden- und bedürfnisorientiert. Der Mensch steht stets im Mittelpunkt.</p>
          <div className="home-characteristics-list">
            <div className="home-characteristics-list-item">
              <p className="home-characteristics-list-item-head">Kompetenz</p>
              <p className="home-characteristics-list-item-desc">Durch langjährige Praxiserfahrung bieten wir Ihnen stets die beste Lösung für Ihr Projekt an. Wir beachten hierbei Ihre Wünsche und gehen auf Ihre Bedürfnisse ein.</p>
            </div>
            <div className="home-characteristics-list-item">
              <p className="home-characteristics-list-item-head">Qualität</p>
              <p className="home-characteristics-list-item-desc">Als Meisterbetrieb mit Eintragung im Installateurverzeichnis können wir Ihnen ein Höchstmaß an Qualität garantieren. Durch regelmäßige Schulungen unserer Mitarbeiter stellen wir sicher, dass Ihre Anlage auf dem Stand der Technik ist.</p>
            </div>
            <div className="home-characteristics-list-item">
              <p className="home-characteristics-list-item-head">Schnelligkeit</p>
              <p className="home-characteristics-list-item-desc">Aus Erfahrung wissen wir sehr gut, dass ein schneller Einsatz manchmal unvermeidlich ist. Deshalb versuchen wir stets flexibel zu bleiben, um Ihr Projekt voranzubringen.</p>
            </div>
          </div>
        </div>

        <div className="gallery-1">
          <div className="gallery-1-item">
            <img className="gallery-1-item-img" alt="" src={Gall1_1} />
            <div className="gallery-1-item-buffer"></div>
          </div>
          <div className="gallery-1-item">
            <div className="gallery-1-item-buffer"></div>
            <img className="gallery-1-item-img" alt="" src={Gall1_2} />
          </div>
          <div className="gallery-1-item">
            <img className="gallery-1-item-img" alt="" src={Gall1_3} />
            <div className="gallery-1-item-buffer"></div>
          </div>
          <div className="gallery-1-item">
            <div className="gallery-1-item-buffer"></div>
            <img className="gallery-1-item-img" alt="" src={Gall1_4} />
          </div>
        </div>

        <div className="home-social">
          <div className="home-social-left">
            <p className="home-social-left-text">Finden Sie uns auch auf den folgenden Social Media Kanälen.</p>
            <p className="home-social-left-text">Unsere Kanäle:</p>
            <div className="home-social-left-icons">
              <a href="https://https://www.instagram.com/strombaeren/?r=nametag" target="_blank">
                <img className="home-social-left-icons-icon" alt="Instagram" src={Instagram} />
              </a>
              <a href="https://www.linkedin.com/company/stromb%C3%A4ren-gmbh/about/" target="_blank">
                <img className="home-social-left-icons-icon" alt="Linkedin" src={Linkedin} />
              </a>
            </div>
          </div>
          <div className="home-social-right">
            <p className="home-social-right-text">Folgen Sie uns auch auf Social Media</p>
          </div>
        </div>

        <div className="home-partner">
          <div className="home-partner-innung">
            <div className="home-partner-innung-ghost">

            </div>
            <img className="home-partner-innung-img" alt="" src={Innung} />
          </div>
        </div>

        <div className="home-team">
          <div className="home-team-main">
            <div className="home-team-container">
              <p className="home-team-container-head">WERDEN SIE TEIL UNSERES TEAMS</p>
              <p className="home-team-container-desc">Die StromBären suchen Verstärkung. Profitiere von einem jungen und motiviertem Team mit einer herzlichen und familiären Atmosphäre.</p>
              <p className="home-team-container-desc">Innerhalb weniger Minuten eine Bewerbung abschicken!</p>
              <a href="/career" className="home-team-container-bewerben">
                <p className="home-team-container-bewerben-text">JETZT BEWERBEN</p>
              </a>
            </div>
            <img className="home-team-img" alt="TeamMember" src={TeamMember} />
          </div>
          <div className="home-team-blueleft"></div>
          <div className="home-team-bluerigth"></div>
        </div>

        <div className="home-about">
          <div className="home-about-gallery">
            <img className="home-about-gallery-1" alt="Ladestation" src={AboutGal4} />
          </div>
          <div className="home-about-text">
            <div className="home-about-text-left">
              <p className="home-about-text-left-head">Mehr über Elektro-technik</p>
              <p className="home-about-text-left-text">Elektrotechnik ist nicht nur: "der Strom aus der Steckdose!" oder "das Licht in der Lampe." Dahinter steckt ein komplexes Zusammenspiel, bei dem vom Erzeuger bis Verbraucher alles reibungslos und Hand-in-Hand ablaufen muss, damit der Wasserkocher das Wasser kocht - und nicht die Küche abbrennt</p>
            </div>
            <div className="home-about-text-right">
              <p className="home-about-text-right-text">Als Elektroinstallateure für Gebäudetechnik haben wir in einem sehr wechselhaften Teil dieser Kette unsere Verantwortung: Beim Verbraucher und Endkunden.</p>
              <a href="/services" className="home-about-container-services">
                <p className="home-about-container-services-text">ZU UNSEREN LEISTUNGEN</p>
              </a>
            </div>
          </div>

        </div>

        <div className="home-contact">
          <div className="home-contact-wrapper">
            <div className="home-contact-left">
              <p className="home-contact-left-text">Interesse geweckt?</p>
            </div>
            <div className="home-contact-right">
              <p className="home-contact-right-text">Dann melden Sie sich doch bei uns. Wir sind stets für Sie da.</p>
              <div className="home-contact-right-button">
                <a href="/contact" className="home-contact-right-text">KONTAKTIEREN SIE UNS</a>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Footer></Footer>
    </>
  );
}

export default App;


/*

        <div className="home-contact">
          <div className="home-contact-ready">
            <p className="home-contact-head">Bereit für uns?</p>
            <p className="home-contact-subhead">Schicken Sie uns eine Mail</p>
            <div className="home-contact-sendmail-1">
              <a href="/contact">EMAIL SENDEN</a>
            </div>
          </div>
          <div className="home-contact-questions">
            <p className="home-contact-head">Noch Fragen?</p>
            <p className="home-contact-subhead">Rufen Sie uns gerne an</p>
            <div className="home-contact-sendmail-2">
              <a href="/contact">ZUM KONTAKT</a>
            </div>
          </div>
        </div>

*/