import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./Company.scss";


//import Team from '../../assets/graphics/team1.jpg';
import Member1 from '../../assets/graphics/mitarbeiter-0.jpg';
import Member2 from '../../assets/graphics/mitarbeiter-1.jpg';
import Member3 from '../../assets/graphics/mitarbeiter-2.jpg';
import Member4 from '../../assets/graphics/mitarbeiter-3.jpg';
import MainQuarter from '../../assets/graphics/about-headquarter.png';

import EFRE from '../../assets/graphics/efre.jpg';


//<img className="company-head-img" alt="" src={Team} />

/*
                        <div className="company-team-row-child">
                            <img className="company-team-row-child-img" alt="" src={Member4} />
                            <p className="company-team-row-child-name">Marek</p>
                            <p className="company-team-row-child-desc">Elektrofachkraft für Energie- und Gebäudetechnik</p>
                        </div>
                        <div className="company-team-row-child">
                            <img className="company-team-row-child-img" alt="" src={Member2} />
                            <p className="company-team-row-child-name">Bartek</p>
                            <p className="company-team-row-child-desc">Auszubildene Elektrofachkraft für Energie und Gebäudetechnik</p>
                        </div>
*/

function Company() {
    return (
        <>
            <Header></Header>
            <div className="company">
                <div className="company-head-container">
                    <div className="company-head">
                        <div className="company-head-img">
                            <h1 className= "company-head-img-h1">StromBären Gmbh</h1>
                        </div>    
                        
                        <div className="company-head-text">
                            <p className="company-head-text-head">Planen. Ausführen. Begeistern.</p>
                            <p className="company-head-text-desc">Wir sind die StromBären GmbH. Ein inhabergeführtes Elektroinstallationsunternehmen in dritter Generation, welches 2021 neu gegründet wurde als Nachfolger des bereits etablierten und erfolgreichen Unternehmens Elektrotechnik Kubalczak mit Sitz in Berlin.</p>
                            <p className="company-head-text-desc">Über die jahrelange Erfahrung in der Berlin/Brandenburger Baubranche haben wir uns viel Expertise aufgebaut und Herausforderungen gemeistert, vor der manch Andere zurückschrecken.</p>
                        </div>
                    </div>
                </div>
                <div className="company-mid-container">
                    <div className="company-mid">
                        <p className="company-mid-head">Wir möchten innovative Neuerungen in Ihrem Haushalt installieren.</p>
                        <p className="company-mid-desc">Unsere Tätigkeitsfelder erstrecken sich von der klassischen <b>Elektroinstallation in Einfamilienhäusern</b>, über den <b>Netzanschluss von Photovoltaikanlagen</b> sowie <b>komplexere Mehrfamilienhaus Projekte.</b> Wir sind als Unternehmen, welches im Installateurverzeichnis des Netzbetreibers eingetragen ist, berechtigt sämtliche Elektroinstallationsarbeiten auszuführen, die hinter dem Hausanschluss eines Gebäudes liegen.</p>
                    </div>
                </div>


                <div className="company-team">
                    <p className="company-team-head"> Unser Team </p>
                    <p className="company-team-subhead"> Unverwechselbar durch Erfahrung, Leidenschaft und Engagement.</p>

                    <div className="company-team-row">
                        <div className="company-team-row-child">
                            <img className="company-team-row-child-img" alt="" src={Member1} />
                            <p className="company-team-row-child-name">Vincent</p>
                            <p className="company-team-row-child-desc">Inhaber<br></br> und Elektromeister<br></br> der Strombären GmbH</p>
                        </div>
                        <div className="company-team-row-child">
                            <img className="company-team-row-child-img" alt="" src={Member3} />
                            <p className="company-team-row-child-name">Jacek</p>
                            <p className="company-team-row-child-desc">Elektrofachkraft für Energie-<br></br> und Gebäudetechnik sowie <br></br>Telekommunikationtechnik</p>
                        </div>
                    </div>
                </div>

                <div className="company-locations-container">
                    <div className="company-locations">
                        <p className="company-locations-head">Standorte</p>
                        <div className="company-locations-wrapper">
                            <div className="company-locations-main">
                                <p className="company-locations-main-head">Büroniederlassung</p>
                                <img className="company-locations-main-img" alt="" src={MainQuarter} />
                                <p className="company-locations-main-text">Hindenburgdamm 56</p>
                                <p className="company-locations-main-text">12203, Berlin-Steglitz</p>
                                <p className="company-locations-main-text">info@strombaeren.de</p>
                            </div>
                            <iframe className="company-locations-maps" src="//maps.google.com/maps?output=embed&amp;q=Hindenburgdamm%2056%20Berlin&amp;z=18&amp;t=m" data-map="JTdCJTIycG9zaXRpb25UeXBlJTIyJTNBJTIybWFwLWFkZHJlc3MlMjIlMkMlMjJhZGRyZXNzJTIyJTNBJTIySGluZGVuYnVyZ2RhbW0lMjA1NiUyMEJlcmxpbiUyMiUyQyUyMnpvb20lMjIlM0ExOCUyQyUyMnR5cGVJZCUyMiUzQSUyMnJvYWQlMjIlMkMlMjJsYW5nJTIyJTNBbnVsbCUyQyUyMmFwaUtleSUyMiUzQW51bGwlMkMlMjJtYXJrZXJzJTIyJTNBJTVCJTVEJTdE"></iframe>
                        </div>
                    </div>
                </div>

                <div className="company-efre">
                    <div className="company-efre-content">
                        <img className="company-efre-content-img" alt="" src={EFRE} />
                        <p className="company-efre-content-text">Unser Meisterbetrieb hat eine Meistergründungsprämie erhalten, die mit Mitteln aus dem Europäischen Fonds für die regionale Enwicklung ko-finanziert worden ist.</p>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default Company;
