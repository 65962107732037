import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./Mail.scss";


function Mail_error() {
    return (
        <>
            <Header></Header>
            <div className="mail-page">
                <div className="mail-page-text">
                    <p className="mid"> Uuuups :(</p>
                    <p className="small">Leider ist etwas beim Versand Ihrer Nachricht schiefgelaufen. Bitte kontatkieren Sie uns telefonisch. </p>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default Mail_error;
