import "./Header.scss";

import Logo from '../../assets/icons/logo_row_color_black.svg';

import Instagram from '../../assets/icons/instagram_header.svg';
import Linkedin from '../../assets/icons/linkedin_header.svg';

import Burger from '../../assets/icons/menu.svg';

import React, { useState } from "react"

import { MdClose } from "react-icons/md"
//import { FiMenu } from "react-icons/fi"

function Header() {
    const [navbarOpen, setNavbarOpen] = useState(false)

    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
    }

    const closeMenu = () => {
        setNavbarOpen(false)
    }

    return (
        <>
            <header id="app-header">
                <nav className='app-nav'>
                    <a href="/">
                        <img className="app-header-logo" alt="" src={Logo} />
                    </a>
                    <div className='app-nav-menu'>
                        <a href="/services">Leistungen</a>
                        <a href="/about">Unternehmen</a>
                        <a href="/career">Karriere</a>
                        <div className="app-nav-contact">
                            <a href="/contact"><p>KONTAKT</p></a>
                        </div>
                    </div>
                    <nav className="app-nav-ham-menu">
                        <button className="app-nav-ham-menu-button" onClick={handleToggle}>
                            {navbarOpen ? (
                                <MdClose style={{ color: "#000", width: "32px", height: "32px" }} />
                            ) : (
                                <img className="app-nav-burger-menu" alt="" src={Burger} />
                            )}
                        </button>
                        <div className={`app-nav-ham-menu-side ${navbarOpen ? " showMenu" : ""}`}>
                            <div className="app-nav-ham-menu-side-wrapper-a">
                                <a className="app-nav-ham-menu-side-a" href="/" onClick={closeMenu}><p className="app-nav-ham-menu-side-a-p">Home</p></a>
                                <a className="app-nav-ham-menu-side-a" href="/services" onClick={closeMenu}><p className="app-nav-ham-menu-side-a-p">Leistungen</p></a>

                                <a className="app-nav-ham-menu-side-a" href="/about" onClick={closeMenu}><p className="app-nav-ham-menu-side-a-p">Unternehmen</p></a>
                                <a className="app-nav-ham-menu-side-a" href="/career" onClick={closeMenu}><p className="app-nav-ham-menu-side-a-p">Karriere</p></a>
                            </div>

                            <div className="side-line"></div>

                            <div className='app-nav-social'>
                                <a href="https://www.instagram.com/strombaeren?r=nametag" target="_blank">
                                    <img alt="Instagram" src={Instagram} />
                                </a>
                                <a href="https://www.instagram.com/strombaeren?r=nametag" target="_blank">
                                    <img alt="LinkedIn" src={Linkedin} />
                                </a>
                            </div>
                        </div>
                    </nav>
                </nav>
            </header>
        </>

    );
}
export default Header;