import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./Privacy.scss";


import privacy1 from '../../assets/graphics/Datenschutz0.png';
import privacy2 from '../../assets/graphics/Datenschutz1.png';

function Privacy() {
    return (
        <>
            <Header></Header>
            <div className="privacy">
                <div className="privacy-content">

                    <p className="head">Datenschutz</p>
                    <p className="p1">Inhaltsverzeichnis</p>
                    <ul>
                        <li className="p3">Einleitung und Überblick</li>
                        <li className="p3">Anwendungsbereich</li>
                        <li className="p3">Rechtsgrundlagen</li>
                        <li className="p3">Kontaktdaten des Verantwortlichen</li>
                        <li className="p3">Rechte laut Datenschutzgrundverordnung</li>
                        <li className="p3">Speicherdauer</li>
                        <li className="p3">Kommunikation</li>
                        <li className="p3">Webhosting</li>
                        <li className="p3">Cookies</li>
                        <li className="p3">Google Fonts Lokal Datenschutzerklärung</li>
                        <li className="p3">OpenStreetMap Datenschutzerklärung</li>
                        <li className="p3">Google Maps Datenschutzerklärung</li>
                        <li className="p3">Google Fonts Datenschutzerklärung</li>
                    </ul>

                    <p className="p1">Einleitung und Überblick</p>
                    <p className="p3">Wir haben diese Datenschutzerklärung (Fassung 14.07.2021-321290300) verfasst, um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche – und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) – verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.
                        <br></br><b>Kurz gesagt:</b> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
                    <p className="p3">Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische <b>Begriffe leserfreundlich erklärt</b>, Links zu weiterführenden Informationen geboten und <b>Grafiken</b> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.
                        Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>

                    <p className="p1">Anwendungsbereich</p>

                    <p className="p3">Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
                    <ul>
                        <li className="p3">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
                        <li className="p3">Social Media Auftritte und E-Mail-Kommunikation</li>
                        <li className="p3"> mobile Apps für Smartphones und andere Geräte</li>
                    </ul>

                    <p className="p3"><b>Kurz gesagt:</b>: Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>


                    <p className="p3">Rechtsgrundlagen</p>

                    <p className="p3">
                        In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.
                        Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter  <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679" target="_blank"><p className="p3">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679</p></a> nachlesen.</p>

                    <p className="p3">
                        Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>

                    <p className="p3">
                        <b>1. Einwilligung</b> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</p>
                    <p className="p3">
                        <b>2. Vertrag</b> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.</p>

                    <p className="p3">
                        <b>3. Rechtliche Verpflichtung</b> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</p>
                    <p className="p3">
                        <b>4. Berechtigte Interessen</b> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</p>

                    <p className="p3">
                        Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>

                    <p className="p3">
                        Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>

                    <ul>
                        <li className="p3">In <b>Österreich</b> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<b>Datenschutzgesetz</b>), kurz DSG.</li>
                        <li className="p3">In <b>Deutschland</b> <b>gilt das Bundesdatenschutzgesetz</b>, kurz <b>BDSG</b>.</li>
                    </ul>

                    <p className="p3">
                        Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.</p>

                    <p className="p1">
                        Kontaktdaten des Verantwortlichen</p>

                    <p className="p3">
                        Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:</p>


                    <p className="p3">
                        Strombären GmbH<br></br>
                        Ludwigshöheweg 38,<br></br>
                        12559 Berlin, Deutschland<br></br>
                        Vertretungsberechtigt: Vincent Kubalczak</p>

                    <p className="p3">
                        Tel.: 0176 80236346<br></br>
                        E-Mail: info@strombaeren.de<br></br>
                        Impressum: Impressum</p>


                    <p className="p1">
                        Rechte laut Datenschutzgrundverordnung</p>

                    <p className="p3">
                        Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>

                    <ul>
                        <li className="p3">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
                            zu welchem Zweck wir die Verarbeitung durchführen;
                            die Kategorien, also die Arten von Daten, die verarbeitet werden;
                            wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann;
                            wie lange die Daten gespeichert werden;
                            das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;
                            dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten);
                            die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;
                            ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
                        <li className="p3">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
                        <li className="p3">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
                        <li className="p3">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
                        <li className="p3">Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
                        <li className="p3">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.</li>
                        <li className="p3">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
                        <li className="p3">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.</li>
                        <li className="p3">Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</li>
                        <li className="p3">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
                    </ul>


                    <p className="p3">
                        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a href="https://www.dsb.gv.at/" target="_blank"><p className="p3">https://www.dsb.gv.at/</p></a> finden und für Deutschland können Sie sich an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI) wenden.</p>
                    <p className="p3">
                        <b>Kurz gesagt:</b>: Sie haben Rechte – zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>

                    <p className="p1">
                        Speicherdauer</p>

                    <p className="p3">
                        Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>

                    <p className="p3">
                        Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>

                    <p className="p3">
                        Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>

                    <p className="p1">
                        Kommunikation</p>

                    <p className="p3">
                        <b>Kommunikation Zusammenfassung</b><br></br>
                        👥 Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br></br>
                        📓 Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br></br>
                        🤝 Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br></br>
                        📅 Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br></br>
                        ⚖️ Rechtsgrundlagen: <b>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</b>, Art. 6 Abs. 1 lit. b DSGVO (Vertrag), <b>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</b></p>

                    <p className="p3">
                        Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>

                    <p className="p3">
                        Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während ebensolange gespeichert bzw. so lange es das Gesetz vorschreibt.</p>


                    <p className="p1">
                        Betroffene Personen</p>

                    <p className="p3">
                        Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>

                    <p className="p1">
                        Telefon</p>

                    <p className="p3">
                        Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>

                    <p className="p1">
                        E-Mail</p>

                    <p className="p3">
                        Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,…) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>

                    <p className="p1">
                        Online Formulare</p>

                    <p className="p3">
                        Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>

                    <p className="p1">
                        Rechtsgrundlagen</p>

                    <p className="p3">
                        Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>

                    <ul>
                        <li className="p3"><b>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</b>: Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
                        <li className="p3">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
                        <li className="p3"><b>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</b>: Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.</li>
                    </ul>


                    <p className="p1">
                        Webhosting</p>

                    <p className="p3">
                        <b>Webhosting Zusammenfassung</b><br></br>
                        👥 Betroffene: Besucher der Website<br></br>
                        🤝 Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br></br>
                        📓 Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br></br>
                        📅 Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br></br>
                        ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)<br></br></p>

                    <p className="p1">Was ist Webhosting?</p>

                    <p className="p3">
                        Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen – auch personenbezogene Daten – automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>

                    <p className="p3">
                        Wenn Sie eine Website auf einem Bildschirm ansehen möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari.</p>

                    <p className="p3">
                        Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites.</p>

                    <p className="p3">
                        Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gewährleisten.</p>

                    <img className="privacy-content-image" alt="" src={privacy1} />

                    <p className="p1">
                        Warum verarbeiten wir personenbezogene Daten?</p>

                    <p className="p3">
                        Die Zwecke der Datenverarbeitung sind:</p>

                    <p className="p3">
                        Professionelles Hosting der Website und Absicherung des Betriebs<br></br>
                        zur Aufrechterhaltung der Betriebs- und IT-Sicherheit<br></br>
                        Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen</p>

                    <p className="p1">
                        Welche Daten werden verarbeitet?</p>

                    <p className="p3">
                        Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>

                    <ul>
                        <li className="p3">die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B. https://www.beispielwebsite.de/beispielunterseite.html?tid=321290300)</li>
                        <li className="p3">Browser und Browserversion (z. B. Chrome 87)</li>
                        <li className="p3">das verwendete Betriebssystem (z. B. Windows 10)</li>
                        <li className="p3">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. https://www.beispielquellsite.de/vondabinichgekommen.html/)</li>
                        <li className="p3">den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
                        <li className="p3">Datum und Uhrzeit</li>
                        <li className="p3">Datum und Uhrzeit</li>
                    </ul>

                    <p className="p1">
                        Wie lange werden Daten gespeichert?</p>

                    <p className="p3">
                        In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.</p>

                    <p className="p3">
                        <b>Kurz gesagt:</b>: Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!</p>

                    <p className="p1">
                        Rechtsgrundlage</p>

                    <p className="p3">
                        Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.</p>

                    <p className="p1">
                        Cookies</p>

                    <p className="p3">
                        <b>Cookies Zusammenfassung</b><br></br>
                        👥 Betroffene: Besucher der Website<br></br>
                        🤝 Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br></br>
                        📓 Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br></br>
                        📅 Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren variieren<br></br>
                        ⚖️ Rechtsgrundlagen: <b>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</b>, Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</p>

                    <p className="p1">
                        Was sind Cookies?</p>


                    <p className="p3">
                        Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.
                        Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.</p>

                    <p className="p3">
                        Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>

                    <p className="p3">
                        Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>

                    <p className="p3">
                        Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>

                    <p className="p3">
                        Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der Browser erneut verwendet, sobald eine andere Seite angefordert wird.</p>

                    <img className="privacy-content-image" alt="" src={privacy2} />

                    <p className="p3">
                        Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.</p>

                    <p className="p3">
                        So können zum Beispiel Cookie-Daten aussehen:</p>

                    <p className="p3">
                        <b>Name:</b> _ga <br></br>
                        <b>Wert:</b> GA1.2.1326744211.152321290300-9<br></br>
                        <b>Verwendungszweck:</b> Unterscheidung der Websitebesucher<br></br>
                        <b>Ablaufdatum:</b> nach 2 Jahren</p>

                    <p className="p3">
                        Diese Mindestgrößen sollte ein Browser unterstützen können:</p>

                    <ul>
                        <li className="p3">Mindestens 4096 Bytes pro Cookie</li>
                        <li className="p3">Mindestens 50 Cookies pro Domain</li>
                        <li className="p3">Mindestens 3000 Cookies insgesamt</li>
                    </ul>

                    <p className="p1">
                        Welche Arten von Cookies gibt es?</p>

                    <p className="p3">
                        Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>

                    <p className="p3">
                        Man kann 4 Arten von Cookies unterscheiden:</p>

                    <p className="p3">
                        <b>Unerlässliche Cookies</b><br></br>
                        Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</p>

                    <p className="p3">
                        <b>Zweckmäßige Cookies</b><br></br>
                        Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.</p>

                    <p className="p3">
                        <b>Zielorientierte Cookies</b><br></br>
                        Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>

                    <p className="p3">
                        <b>Werbe-Cookies</b><br></br>
                        Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>

                    <p className="p3">
                        Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>

                    <p className="p3">
                        Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a href="https://tools.ietf.org/html/rfc6265" target="_blank"><p className="p3">https://tools.ietf.org/html/rfc6265</p></a>, dem Request for Comments der Internet Engineering Task Force (IETF) namens “HTTP State Management Mechanism”.</p>

                    <p className="p1">
                        Zweck der Verarbeitung über Cookies</p>

                    <p className="p3">
                        Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.</p>

                    <p className="p1">
                        Welche Daten werden verarbeitet?</p>

                    <p className="p3">
                        Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten informieren.</p>

                    <p className="p1">
                        Speicherdauer von Cookies</p>

                    <p className="p3">
                        Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>

                    <p className="p3">
                        Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies jederzeit manuell löschen (siehe auch unten “Widerspruchsrecht”). Ferner werden Cookies, die auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis dahin unberührt bleibt.</p>


                    <p className="p1">
                        Widerspruchsrecht – wie kann ich Cookies löschen?</p>

                    <p className="p3">
                        Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>

                    <p className="p3">
                        Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle eines Chrome Browsers.</p>

                    <p className="p1">
                        Rechtsgrundlage</p>

                    <p className="p3">
                        Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine <b>Einwilligung</b> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</p>

                    <p className="p3">
                        Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt. bestehen <b>berechtigte Interessen</b> (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>

                    <p className="p3">
                        Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>

                    <p className="p3">
                        In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte Software Cookies verwendet.</p>

                    <p className="p1">
                        Google Fonts Lokal Datenschutzerklärung</p>

                    <p className="p3">
                        Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir haben die Google-Schriftarten lokal, d.h. auf unserem Webserver – nicht auf den Servern von Google – eingebunden. Dadurch gibt es keine Verbindung zu Google-Servern und somit auch keine Datenübertragung oder Speicherung.</p>

                    <p className="p1">
                        Was sind Google Fonts?</p>

                    <p className="p3">
                        Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives Verzeichnis mit über 800 Schriftarten, die Google kostenlos bereitstellt. Mit Google Fonts könnte man Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich jede Informationsübertragung zu Google-Servern zu unterbinden, haben wir die Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden keine Daten an Google Fonts weiter.</p>


                    <p className="p1">
                        OpenStreetMap Datenschutzerklärung</p>

                    <p className="p3">
                        <b>OpenStreetMap Datenschutzerklärung Zusammenfassung</b><br></br>
                        👥 Betroffene: Besucher der Website<br></br>
                        🤝 Zweck: Optimierung unserer Serviceleistung<br></br>
                        📓 Verarbeitete Daten: Daten wie etwa IP-Adresse, Browserinformationen, Ihr Betriebssystem, Inhalt der Anfrage, eingeschränkte Standorts- und Nutzungsdaten<br></br>
                        Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br></br>
                        📅 Speicherdauer: die IP-Adresse wird nach 180 Tagen gelösch<br></br>
                        ⚖️ Rechtsgrundlagen: <b>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</b>, <b>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</b></p>

                    <p className="p2">
                        Was ist OpenStreetMap?</p>

                    <p className="p3">
                        Wir haben auf unserer Website Kartenausschnitte des Online-Kartentools „OpenStreetMap“ eingebunden. Dabei handelt es sich um ein sogenanntes Open-Source-Mapping, welches wir über eine API (Schnittstelle) abrufen können. Angeboten wird diese Funktion von OpenStreetMap Foundation, St John’s Innovation Centre, Cowley Road, Cambridge, CB4 0WS, United Kingdom. Durch die Verwendung dieser Kartenfunktion wird Ihre IP-Adresse an OpenStreetMap weitergeleitet. In dieser Datenschutzerklärung erfahren Sie warum wir Funktionen des Tools OpenStreetMap verwenden, wo welche Daten gespeichert werden und wie Sie diese Datenspeicherung verhindern können.</p>

                    <p className="p3">
                        Das Projekt OpenStreetMap wurde 2004 ins Leben gerufen. Ziel des Projekts ist und war es, eine freie Weltkarte zu erschaffen. User sammeln weltweit Daten etwa über Gebäude, Wälder, Flüsse und Straßen. So entstand über die Jahre eine umfangreiche, von Usern selbst erstellte digitale Weltkarte. Selbstverständlich ist die Karte, nicht vollständig, aber in den meisten Regionen mit sehr vielen Daten ausgestattet.</p>

                    <p className="p2">
                        Warum verwenden wir OpenStreetMap auf unserer Website?</p>

                    <p className="p3">
                        Unsere Website soll Ihnen in erster Linie hilfreich sein. Und das ist sie aus unserer Sicht immer dann, wenn man Information schnell und einfach findet. Da geht es natürlich einerseits um unsere Dienstleistungen und Produkte, andererseits sollen Ihnen auch weitere hilfreiche Informationen zur Verfügung stehen. Deshalb nutzen wir auch den Kartendienst OpenStreetMap. Denn so können wir Ihnen beispielsweise genau zeigen, wie Sie unsere Firma finden. Die Karte zeigt Ihnen den besten Weg zu uns und Ihre Anfahrt wird zum Kinderspiel.</p>

                    <p className="p2">
                        Welche Daten werden von OpenStreetMap gespeichert?</p>

                    <p className="p3">
                        Wenn Sie eine unserer Webseiten besuchen, die OpenStreetMap anbietet, werden Nutzerdaten an den Dienst übermittelt und dort gespeichert. OpenStreetMap sammelt etwa Informationen über Ihre Interaktionen mit der digitalen Karte, Ihre IP-Adresse, Daten zu Ihrem Browser, Gerätetyp, Betriebssystem und an welchem Tag und zu welcher Uhrzeit Sie den Dienst in Anspruch genommen haben. Dafür wird auch Tracking-Software zur Aufzeichnung von Userinteraktionen verwendet. Das Unternehmen gibt hier in der eigenen Datenschutzerklärung das Analysetool „Piwik“ an.</p>

                    <p className="p3">
                        Die erhobenen Daten sind in Folge den entsprechenden Arbeitsgruppen der OpenStreetMap Foundation zugänglich. Laut dem Unternehmen werden persönliche Daten nicht an andere Personen oder Firmen weitergegeben, außer dies ist rechtlich notwendig. Der Drittanbieter Piwik speichert zwar Ihre IP-Adresse, allerdings in gekürzter Form.</p>

                    <p className="p3">
                        Folgendes Cookie kann in Ihrem Browser gesetzt werden, wenn Sie mit OpenStreetMap auf unserer Website interagieren:</p>

                    <p className="p3">
                        <b>Name:</b> _osm_location<br></br>
                        <b>Wert:</b> 9.63312%7C52.41500%7C17%7CM<br></br>
                        <b>Verwendungszweck:</b> Das Cookie wird benötigt, um die Inhalte von OpenStreetMap zu entsperren.<br></br>
                        <b>Ablaufdatum:</b> nach 10 Jahren</p>

                    <p className="p3">
                        Wenn Sie sich das Vollbild der Karte ansehen wollen, werden Sie auf die OpenStreetMap-Website verlinkt. Dort können unter anderem folgende Cookies in Ihrem Browser gespeichert werden:</p>

                    <p className="p3">
                        <b>Name:</b> _osm_totp_token<br></br>
                        <b>Wert:</b> 148253321290300-2<br></br>
                        <b>Verwendungszweck:</b> Dieses Cookie wird benutzt, um die Bedienung des Kartenausschnitts zu gewährleisten.<br></br>
                        <b>Ablaufdatum:</b> nach einer Stunde</p>

                    <p className="p3">
                        <b>Name:</b> _osm_session<br></br>
                        <b>Wert:</b> 1d9bfa122e0259d5f6db4cb8ef653a1c<br></br>
                        <b>Verwendungszweck:</b> Mit Hilfe des Cookies können Sitzungsinformationen (also Userverhalten) gespeichert werden.<br></br>
                        <b>Ablaufdatum:</b> nach Sitzungsende</p>

                    <p className="p3">
                        <b>Name:</b> _pk_id.1.cf09<br></br>
                        <b>Wert:</b> 4a5.1593684142.2.1593688396.1593688396321290300-9<br></br>
                        <b>Verwendungszweck:</b> Dieses Cookie wird von Piwik gesetzt, um Userdaten wie etwa das Klickverhalten zu speichern bzw. zu messen.<br></br>
                        <b>Ablaufdatum:</b> nach einem Jahr</p>

                    <p className="p1">
                        Wie lange und wo werden die Daten gespeichert?</p>

                    <p className="p3">
                        Die API-Server, die Datenbanken und die Server von Hilfsdiensten befinden sich derzeit im Vereinten Königreich (Großbritannien und Nordirland) und in den Niederlanden. Ihre IP-Adresse und Userinformationen, die in gekürzter Form durch das Webanalysetool Piwik gespeichert werden, werden nach 180 Tagen wieder gelöscht.</p>


                    <p className="p1">
                        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</p>

                    <p className="p3">
                        Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und Einspruch gegen die Nutzung und Verarbeitung zu erheben. Cookies, die von OpenStreetMap möglicherweise gesetzt werden, können Sie in Ihrem Browser jederzeit verwalten, löschen oder deaktivieren. Dadurch wird allerdings der Dienst nicht mehr im vollen Ausmaß funktionieren. Bei jedem Browser funktioniert die Verwaltung, Löschung oder Deaktivierung von Cookies etwas anders.</p>


                    <p className="p1">
                        Rechtsgrundlage</p>

                    <p className="p3">
                        Wenn Sie eingewilligt haben, dass OpenStreetMap eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut <b>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</b> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch OpenStreetMap vorkommen kann, dar.</p>

                    <p className="p3">
                        Von unserer Seite besteht zudem ein berechtigtes Interesse, OpenStreetMap zu verwenden, um unser Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist Art. <b>6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</b>. Wir setzen OpenStreetMap gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>

                    <p className="p3">
                        Wenn Sie mehr über die Datenverarbeitung durch OpenStreetMap erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung des Unternehmens unter <a href="https://wiki.osmfoundation.org/wiki/Privacy_Policy." target="_blank"><p className="p3">https://wiki.osmfoundation.org/wiki/Privacy_Policy.</p></a>
                    </p>


                    <p className="p1">
                        Google Maps Datenschutzerklärung</p>

                    <p className="p3">
                        <b>Google Maps Datenschutzerklärung Zusammenfassung</b><br></br>
                        👥 Betroffene: Besucher der Website<br></br>
                        🤝 Zweck: Optimierung unserer Serviceleistung<br></br>
                        📓 Verarbeitete Daten: Daten wie etwa eingegebene Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw. Längenkoordinaten.<br></br>
                        Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br></br>
                        📅 Speicherdauer: abhängig von den gespeicherten Daten<br></br>
                        ⚖️ Rechtsgrundlagen: <b>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</b>, <b>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</b></p>

                    <p className="p2">
                        Was ist Google Maps?</p>

                    <p className="p3">
                        Wir benützen auf unserer Website Google Maps der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit Google Maps können wir Ihnen Standorte besser zeigen und damit unser Service an Ihre Bedürfnisse anpassen. Durch die Verwendung von Google Maps werden Daten an Google übertragen und auf den Google-Servern gespeichert. Hier wollen wir nun genauer darauf eingehen, was Google Maps ist, warum wir diesen Google-Dienst in Anspruch nehmen, welche Daten gespeichert werden und wie Sie dies unterbinden können.</p>

                    <p className="p3">
                        Google Maps ist ein Internet-Kartendienst der Firma Google. Mit Google Maps können Sie online über einen PC, ein Tablet oder eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen suchen. Wenn Unternehmen auf Google My Business vertreten sind, werden neben dem Standort noch weitere Informationen über die Firma angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte eines Standorts mittels HTML-Code in eine Website eingebunden werden. Google Maps zeigt die Erdoberfläche als Straßenkarte oder als Luft- bzw. Satellitenbild. Dank der Street View Bilder und den hochwertigen Satellitenbildern sind sehr genaue Darstellungen möglich.</p>


                    <p className="p2">
                        Warum verwenden wir Google Maps auf unserer Website?</p>

                    <p className="p3">
                        All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf unserer Webseite zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten Informationen zu diversen Standorten liefern. Sie sehen auf einen Blick wo wir unseren Firmensitz haben. Die Wegbeschreibung zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für Routen mit dem Auto, mit öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist die Bereitstellung von Google Maps Teil unseres Kundenservice.</p>

                    <p className="p2">
                        Welche Daten werden von Google Maps gespeichert?</p>

                    <p className="p3">
                        Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw. Längenkoordinaten. Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse gespeichert. Diese Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie darüber nur informieren, aber keinen Einfluss nehmen. Da wir Google Maps in unsere Webseite eingebunden haben, setzt Google mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten. Google nutzt diese Daten in erster Linie, um eigene Dienste zu optimieren und individuelle, personalisierte Werbung für Sie bereitzustellen.</p>

                    <p className="p3">
                        Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:</p>

                    <p className="p3">
                        Name: NID<br></br>
                        Wert: 188=h26c1Ktha7fCQTx8rXgLyATyITJ321290300-5<br></br>
                        Verwendungszweck: NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am häufigsten eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, die Google benutzt, um Ihre persönlichen Einstellungen für Werbezwecke zu sammeln.<br></br>
                        Ablaufdatum: nach 6 Monaten</p>

                    <p className="p3">
                        Anmerkung: Wir können bei den Angaben der gespeicherten Daten keine Vollständigkeit gewährleisten. Speziell bei der Verwendung von Cookies sind Veränderungen nie auszuschließen. Um das Cookie NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich Google Maps eingebunden war.</p>

                    <p className="p2">
                        Wie lange und wo werden die Daten gespeichert?</p>

                    <p className="p3">
                        Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: <a href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank"><p className="p3">https://www.google.com/about/datacenters/inside/locations/?hl=de</p></a></p>

                    <p className="p3">
                        Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und werden vor etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle Notfallprogramme. Wenn es zum Beispiel Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die Server lahm legt, bleiben die Daten ziemlich sicher trotzdem geschützt.</p>

                    <p className="p3">
                        Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum Beispiel Werbedaten) in Serverprotokollen, indem es einen Teil der IP-Adresse und Cookie-Informationen nach 9 bzw.18 Monaten löscht.</p>

                    <p className="p2">
                        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</p>

                    <p className="p3">
                        Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen zur Standortbestimmung und Web-/App-Aktivität – abhängig von Ihrer Entscheidung – entweder 3 oder 18 Monate gespeichert und dann gelöscht. Zudem kann man diese Daten über das Google-Konto auch jederzeit manuell aus dem Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen, müssen Sie im Google-Konto die Rubrik „Web- und App-Aktivität“ pausieren. Klicken Sie „Daten und Personalisierung“ und dann auf die Option „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- oder ausschalten.</p>

                    <p className="p3">
                        In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies immer etwas anders.</p>

                    <p className="p3">
                        Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>

                    <p className="p3">
                        Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem außereuropäischen Dienstleister gibt.</p>

                    <p className="p2">
                        Rechtsgrundlage</p>

                    <p className="p3">
                        Wenn Sie eingewilligt haben, dass Google Maps eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut <b>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</b> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google Maps vorkommen kann, dar.</p>

                    <p className="p3">
                        Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Maps zu verwenden, um unser Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <b>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</b>. Wir setzen Google Maps gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>

                    <p className="p3">
                        Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch Google Maps. Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann ferner vorkommen, dass diese Daten mit Daten aus möglichen anderen Diensten von Google, bei denen Sie ein Nutzerkonto haben, verknüpft werden.</p>

                    <p className="p3">
                        Wenn Sie mehr über die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene Datenschutzerklärung des Unternehmens unter <a href="https://policies.google.com/privacy?hl=de." target="_blank"><p className="p3">https://policies.google.com/privacy?hl=de.</p></a></p>


                    <p className="p1">
                        Google Fonts Datenschutzerklärung</p>

                    <p className="p3">
                        <b>Google Fonts Datenschutzerklärung Zusammenfassung</b><br></br>
                        👥 Betroffene: Besucher der Website<br></br>
                        🤝 Zweck: Optimierung unserer Serviceleistung<br></br>
                        📓 Verarbeitete Daten: Daten wie etwa IP-Adresse und CSS- und Schrift-Anfragen<br></br>
                        Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br></br>
                        📅 Speicherdauer: Font-Dateien werden bei Google ein Jahr gespeichert<br></br>
                        ⚖️ Rechtsgrundlagen: <b>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</b>, <b>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</b></p>

                    <p className="p2">
                        Was sind Google Fonts?</p>

                    <p className="p3">
                        Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>

                    <p className="p3">
                        Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.</p>

                    <p className="p3">
                        Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die Google Ihren Nutzern kostenlos zu Verfügung stellen.</p>

                    <p className="p3">
                        Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p>

                    <p className="p2">
                        Warum verwenden wir Google Fonts auf unserer Website?</p>

                    <p className="p3">
                        Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.</p>

                    <p className="p2">
                        Welche Daten werden von Google gespeichert?</p>

                    <p className="p3">
                        Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich.</p>

                    <p className="p3">
                        Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.</p>

                    <p className="p3">
                        Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.</p>

                    <p className="p2">
                        Wie lange und wo werden die Daten gespeichert?</p>

                    <p className="p3">
                        Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.</p>

                    <p className="p3">
                        Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.</p>

                    <p className="p2">
                        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</p>

                    <p className="p3">
                        Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie den Google-Support auf <a href="https://support.google.com/?hl=de&tid=321290300" target="_blank"><p className="p3">https://support.google.com/?hl=de&tid=321290300</p></a> kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>


                    <p className="p3">
                        Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf <a href="https://developers.google.com/fonts/faq?tid=321290300" target="_blank"><p className="p3">https://developers.google.com/fonts/faq?tid=321290300</p></a>. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen.</p>


                    <p className="p2">
                        Rechtsgrundlage</p>

                    <p className="p3">
                        Wenn Sie eingewilligt haben, dass Google Fonts eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut <b>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</b> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google Fonts vorkommen kann, dar.</p>

                    <p className="p3">
                        Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Font zu verwenden, um unser Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <b>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</b>. Wir setzen Google Font gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>

                    <p className="p3">
                        Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch Google Fonts. Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann ferner vorkommen, dass diese Daten mit Daten aus möglichen anderen Diensten von Google, bei denen Sie ein Nutzerkonto haben, verknüpft werden.</p>

                    <p className="p3">
                        Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf <a href="https://www.google.com/intl/de/policies/privacy/" target="_blank"><p className="p3">https://www.google.com/intl/de/policies/privacy/</p></a> nachlesen.</p>

                    <p className="p3">
                        Alle Texte sind urheberrechtlich geschützt.</p>

                    <p className="p4">
                        Quelle: Erstellt mit dem Datenschutz Generator von AdSimple</p>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default Privacy;
