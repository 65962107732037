import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./Notfound.scss";


function NotFound() {
    return (
        <>
            <Header></Header>
            <div className="not-found-page">
                <div className="not-found-page-text">
                    <p className="head">404</p>
                    <p className="mid"> Falsche Anfrage ...</p>
                    <p className="small">Überprüfen Sie ob Sie die Adresse richtig eingegeben haben, oder klicken Sie auf das Logo</p>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default NotFound;
